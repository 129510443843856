<template>
  <a-modal
    :visible="isVisible"
    class="max-w-xl md:max-w-xl"
    width="100%"
    :title="[isEdit ? 'Editar Usuario' : 'Nuevo Usuario']"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    :maskClosable="false"
  >
    <a-form :model="formState" :rules="rules" ref="formRef" layout="vertical">
      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Número de documento" name="usuario" class="w-full">
          <a-input-search
            :loading="isUserSearchLoading"
            v-model:value="formState.usuario"
            :maxlength="8"
            @change="onChangeUsuario"
            enter-button
            @search="onSearch"
            :disabled="isEdit"
          />
        </a-form-item>

        <a-form-item label="Fecha de expiración" name="fechaexpirado" class="w-full">
          <a-date-picker
            class="w-7/12"
            v-model:value="formState.fechaexpirado"
            @change="onDatechange"
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYY"
            :disabled="formState.isindefinido"
            :showToday="false"
          />
          <!-- <a-checkbox v-model:checked="true">Indefinido</a-checkbox> -->
          <a-checkbox class="w-5/12 text-right" v-model:checked="formState.isindefinido"
            >Indefinido</a-checkbox
          >
        </a-form-item>
      </div>

      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Apellidos" name="apellido" class="w-full">
          <a-input v-model:value="formState.apellido" />
        </a-form-item>

        <a-form-item label="Nombres" name="nombre" class="w-full">
          <a-input v-model:value="formState.nombre" />
        </a-form-item>
      </div>

      <div class="w-full md:flex md:justify-between md:gap-6">
        <a-form-item label="Correo" name="correo" class="w-full">
          <a-input v-model:value="formState.correo" />
        </a-form-item>

        <a-form-item label="Celular" name="celular" class="w-full">
          <a-input v-model:value="formState.celular" @change="onChangeCelular" />
        </a-form-item>
      </div>

      <a-form-item label="Establecimiento" name="idestablecimiento">
        <a-select
          ref="select"
          v-model:value="formState.idestablecimiento"
          :loading="isLoading"
          show-search
          :filter-option="filterEstablecimiento"
          placeholder="Selecciona el establecimiento"
        >
          <a-select-option title="SIN ESTABLECIMIENTO" value="0"
            >SIN ESTABLECIMIENTO</a-select-option
          >
          <a-select-option
            v-for="establecimiento in establecimientos"
            :value="establecimiento.idestablecimiento"
            :key="establecimiento.idestablecimiento"
            :title="establecimiento.idestablecimiento + ' ' + establecimiento.denominacion"
          >
            {{ establecimiento.denominacion }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Perfiles" name="idsperfiles">
        <!-- <h2 class="my-3">Perfiles:</h2> -->
        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :data-source="perfiles"
          size="small"
          :pagination="false"
          :scroll="{ y: 230 }"
          rowKey="idrol"
          :loading="isLoading"
          :rowClassName="
            (record, index) =>
              index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
          "
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, onMounted, toRaw, ref, computed, watch } from "vue";
import UsuariosApi from "@/api/user";
import { notification } from "ant-design-vue";
import moment from "moment";
import { columnsRoles, formRules } from "./utilsUsuarios";
import EstablecimientoApi from "@/api/establecimiento";
import { atachMultiple, generateArrayAddDelete, filtraPerfilSuperAdmin } from "@/utils";

export default {
  props: {
    usuario: {
      type: Object
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closeModal", "fetchUsuarios", "clearSelectedArrays"],
  setup(props, { emit }) {
    const formRef = ref();
    const formState = reactive(props.usuario);
    const usuarioStatic = props.usuario;
    const isEdit = !!usuarioStatic.idusuario;
    const isUserSearchLoading = ref(false);
    const isLoading = ref(false);
    const perfiles = ref([]);
    const state = reactive({
      initialCheckedKeys: [],
      checkedKeys: [],
      rolesUsuario: []
    });
    // const checkedKeys = ref([]);

    console.log(usuarioStatic);
    const establecimientos = ref([]);

    // const perfiles2 = reactive({
    //   data: [],
    // });

    /* eslint-disable  vue/no-setup-props-destructure*/
    // const usuario = props.usuario.usuario; // se define porque tiene que ser unico en editar
    // const correo = props.usuario.correo;

    // validación de cantidad de caracteres para DNI y celular
    const MAX_LENGTH_DNI = 8;
    const REGEX_NUMEROS = "^[0-9]+$";
    const MAX_LENGTH_CELULAR = 9;

    const closeModal = () => {
      emit("closeModal");
    };

    const getestablecimientos = () => {
      EstablecimientoApi.getByProvincia({ provincia: "TACNA" })
        .then((response) => {
          establecimientos.value = response.data;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const fetchData = () => {
      isLoading.value = true;
      UsuariosApi.getAllRoles()
        .then(async (response) => {
          perfiles.value = response.data;
          perfiles.value = await filtraPerfilSuperAdmin(perfiles.value);
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const fetchUserProfiles = () => {
      UsuariosApi.getUsuarioRolById(usuarioStatic.idusuario)
        .then((res) => {
          state.rolesUsuario = res.data;
          state.initialCheckedKeys = res.data.map((r) => r.idrol);
          state.checkedKeys = res.data.map((r) => r.idrol);
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      fetchData();
      getestablecimientos();
      if (isEdit) {
        fetchUserProfiles();
        formState.isindefinido = !usuarioStatic.fechaexpirado;
      }
    });

    // -------- cuando selecciona perfiles --------
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.checkedKeys,
        onChange: (selectedRowKeys) => {
          state.checkedKeys = selectedRowKeys;
        }
      };
    });

    const handleOk = async () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";

          try {
            if (!state.checkedKeys.length) return notificaPerfilRequerido();

            if (isEdit) {
              const isUnique = usuarioStatic.correo !== formState.correo;

              const isOptionsEquals =
                JSON.stringify(state.initialCheckedKeys) === JSON.stringify(state.checkedKeys);
              console.log(usuarioStatic);

              if (isOptionsEquals) {
                // solo actualiza descripcion o nombre
                console.log(payload);
                response = await UsuariosApi.editOne(payload, usuarioStatic.idusuario);
                msgNotificacion = "El usuario ha sido modificado satisfactoriamente.";
              } else {
                // actualizar todo, descripcion, nombre y rolopcion
                response = await UsuariosApi.editOne(payload, usuarioStatic.idusuario);
                msgNotificacion = "El usuario ha sido modificado satisfactoriamente.";

                const { arrayAdd, arrayDelete } = generateArrayAddDelete(
                  Object.values(state.initialCheckedKeys),
                  Object.values(state.checkedKeys)
                );
                if (arrayAdd.length) {
                  const payloadRoleOpcion = atachMultiple(arrayAdd, usuarioStatic.idusuario);
                  await UsuariosApi.asignRoles(payloadRoleOpcion);
                }
                if (arrayDelete.length) {
                  const arrayIdsDelete = getUsuarioRolIds(arrayDelete);
                  await UsuariosApi.deleteManyRoles(arrayIdsDelete, usuarioStatic.idusuario);
                }
              }

              response = await UsuariosApi.editOne(payload, usuarioStatic.idusuario, isUnique);
              msgNotificacion = "El usuario ha sido modificado correctamente.";
            } else {
              response = await UsuariosApi.addOne(payload);
              console.log(response);
              const idusuario = response.data[0].id;
              console.log(state.checkedKeys);

              const payloadUserRole = atachMultiple(state.checkedKeys, idusuario);
              await UsuariosApi.asignRoles(payloadUserRole);
              await UsuariosApi.correo(payload);
              msgNotificacion = "El usuario ha sido registrado satisfactoriamente.";
            }

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });
            }
            emit("closeModal");
            emit("fetchUsuarios");
            emit("clearSelectedArrays");
          } catch (error) {
            console.error(error.message);
            notification.error({
              message: "Operación inválida",
              // description: "El usuario ya se encuentra registrado"
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    /**
     * getUsuarioRolIds
     * ==============
     * trae ids para eliminarlos
     * @param {Array} arrayDelete
     * @returns {Array} ids rol opcion a eliminar
     * https://stackoverflow.com/a/31201324/9868383
     */
    function getUsuarioRolIds(arrayDelete = []) {
      return Object.values(state.rolesUsuario)
        .filter((a) => arrayDelete.includes(a.idrol))
        .map((a) => a.idusuariorol);
    }

    const handleCancel = () => {
      emit("clearSelectedArrays");
      emit("closeModal");
    };

    const onSearch = (value) => {
      if (value.length != 8) {
        notification.error({
          message: "Operación inválida",
          description: "Ingrese un DNI correcto"
        });
        return;
      }

      isUserSearchLoading.value = true;

      UsuariosApi.getDocumento({ value })
        .then((response) => {
          if (response.data.length > 0) {
            formState.apellido = response.data[0].apellido;
            formState.nombre = response.data[0].nombre;
          } else {
            notification.warning({
              message: "Fallo en la búsqueda",
              description: "Documento no encontrado en nuestra base de datos, ingréselo manualmente"
            });
            formState.apellido = "";
            formState.nombre = "";
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (isUserSearchLoading.value = false));
    };

    const notificaPerfilRequerido = () => {
      notification.error({
        message: "Operación inválida",
        description: "Debes asignar al menos un perfil al usuario"
      });
    };

    const onChangeUsuario = (e) => {
      formState.usuario = e.target.value.slice(0, MAX_LENGTH_DNI);
      if (!new RegExp(REGEX_NUMEROS, "g").test(e.target.value)) {
        formState.usuario = e.target.value.slice(0, -1);
      }
    };

    const onChangeCelular = (e) => {
      formState.celular = e.target.value.slice(0, MAX_LENGTH_CELULAR);
      if (!new RegExp(REGEX_NUMEROS, "g").test(e.target.value)) {
        formState.celular = e.target.value.slice(0, -1);
      }
    };

    const filterEstablecimiento = (input, option) => {
      return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const onDatechange = (dateMoment, dateString) => {
      formState.fechaexpirado = dateMoment;
      formState.format_fecha_exp = dateMoment.format("YYYY-MM-DD");
    };

    watch(
      () => formState.isindefinido,
      (ischecked) => {
        // console.log(checkedKeys)
        if (ischecked) {
          formState.fechaexpirado = null;
          formState.format_fecha_exp = null;
        } else {
          formState.fechaexpirado = moment();
        }
      }
    );

    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      isLoading,
      perfiles,
      onSearch,
      rowSelection,
      establecimientos,
      rules: formRules,
      columns: columnsRoles,
      formRef,
      onChangeUsuario,
      isEdit,
      isUserSearchLoading,
      onChangeCelular,
      filterEstablecimiento,
      onDatechange
    };
  }
};
</script>
