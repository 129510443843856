export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "Usuario",
    dataIndex: "usuario",
    key: "usuario",
    slots: { customRender: "usuario" },
    width: 100
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
    width: 120
  },
  {
    title: "Apellido",
    dataIndex: "apellido",
    key: "apellido",
    width: 180
  },
  {
    title: "Establecimiento",
    dataIndex: "establecimiento",
    key: "establecimiento",
    width: 210
  },
  // {
  //   title: "Correo",
  //   dataIndex: "correo",
  //   key: "correo",
  //   width: 220
  // },
  // {
  //   title: "Celular",
  //   dataIndex: "celular",
  //   key: "celular",
  //   width: 100
  // },
  {
    title: "Expiración",
    dataIndex: "expiracion",
    key: "expiracion",
    width: 100
  },
  {
    title: "Estado",
    dataIndex: "estado",
    key: "estado",
    slots: { customRender: "estado" },
    width: 50
  },
  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "action" },
    width: 80
  }
];

export const columnsRoles = [
  {
    title: "Nombre",
    dataIndex: "denominacion",
    key: "denominacion",
    width: 150
  },
  {
    title: "Descipción",
    dataIndex: "descripcion",
    key: "descripcion",
    width: 220
  }
];

export const breadcrumb = [
  {
    path: "app",
    breadcrumbName: "Configuración"
  },
  {
    path: "/usuarios",
    breadcrumbName: "Usuarios"
  }
];

// --------- para add y edit usuario ----------------
const MAX_LENGTH_DNI = 8;
const MAX_LENGTH_CELULAR = 9;

export const formRules = {
  idestado: [{ required: true, message: "El campo estado debe ser seleccionado", trigger: "blur" }],
  usuario: [
    {
      required: true,
      message: "El campo documento no puede estar vacío",
      trigger: "blur"
    },
    {
      max: MAX_LENGTH_DNI,
      message: "La cantidad máxima de caracteres es de 8",
      trigger: "blur"
    },
    {
      min: MAX_LENGTH_DNI,
      message: "La cantidad mínima de caracteres es de 8",
      trigger: "blur"
    }
  ],
  correo: [
    {
      required: true,
      message: "El campo correo no puede estar vacío",
      trigger: "blur"
    },
    {
      type: "email",
      message: "Formato de correo inválido"
    }
  ],
  apellido: [
    {
      required: true,
      message: "El campo apellido no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 50,
      message: "La cantidad máxima de caracteres es de 8",
      trigger: "blur"
    }
  ],
  nombre: [
    {
      required: true,
      message: "El campo nombre no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 50,
      message: "La cantidad máxima de caracteres es de 8",
      trigger: "blur"
    }
  ],
  celular: [
    {
      max: MAX_LENGTH_CELULAR,
      message: "La cantidad máxima de caracteres es de 9",
      trigger: "blur"
    }
  ]
  // celular: [
  //   {
  //     max: MAX_LENGTH_CELULAR,
  //     message: "La cantidad máxima de caracteres es de 9",
  //     trigger: "blur"
  //   }
  // ]
};

// [
//   {
//       "idusuario": "53",
//       "usuario": "41442065",
//       "nombre": "JEANNET KARINA",
//       "apellido": "VASQUEZ,LAQUITA",
//       "correo": "prueba2aa@gmail.com",
//       "celular": "931375941",
//       "fechaexpirado": "2021-10-22 06:44:10",
//       "activo": "1",
//       "idestablecimiento": "15402",
//       "idestado": "1",
//       "estado": "Vigente"
//   },
//   {
//       "idusuario": "55",
//       "usuario": "40078533",
//       "nombre": "CARMEN ROSA",
//       "apellido": "PAYES,MAMANI",
//       "correo": "prueba2awdawd@gmail.com",
//       "celular": "931375941",
//       "fechaexpirado": "2021-10-22 06:45:49",
//       "activo": "1",
//       "idestablecimiento": "15402",
//       "idestado": "1",
//       "estado": "Vigente"
//   },
//   {
//       "idusuario": "62",
//       "usuario": "76782317",
//       "nombre": "CYNTHIA KATHERINE",
//       "apellido": "MAMANI,HUANCA",
//       "correo": "a_blopeffzv@unjbg.edu.pe",
//       "celular": "",
//       "fechaexpirado": "0000-00-00 00:00:00",
//       "activo": "1",
//       "idestablecimiento": "0",
//       "idestado": "2",
//       "estado": "Inactivo"
//   },
//   {
//       "idusuario": "77",
//       "usuario": "00493666",
//       "nombre": "MARIBEL ROXANA",
//       "apellido": "PEREZ,AYCA",
//       "correo": "a_blopezv@unjbg.edu.pe",
//       "celular": "232342",
//       "fechaexpirado": "0000-00-00 00:00:00",
//       "activo": "1",
//       "idestablecimiento": "15402",
//       "idestado": "1",
//       "estado": "Vigente"
//   },
// ]
