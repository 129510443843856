<template>
  <a-modal
    :visible="true"
    title="DETALLE DE USUARIO"
    class="max-w-sm md:max-w-lg"
    width="100%"
    @close="closeModal"
    @cancel="closeModal"
    :footer="false"
  >
    <a-list size="small">
      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Usuario</span>
        <span class="font-bold md:font-normal">{{ usuario?.usuario }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Apellidos</span>
        <span class="font-bold md:font-normal">{{ usuario?.apellido }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Nombres</span>
        <span class="font-bold md:font-normal">{{ usuario?.nombre }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Establecimiento</span>
        <span class="font-bold md:font-normal">{{ usuario?.establecimiento }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Correo electrónico</span>
        <span class="font-bold md:font-normal">{{ usuario?.correo }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Celular</span>
        <span class="font-bold md:font-normal">{{ usuario?.celular }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Estado</span>
        <a-tag :color="estadoTag.color" class="px-2 py-1">
          {{ estadoTag.nombre }}
        </a-tag>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Expiración</span>
        <span class="font-bold md:font-normal">{{ usuario?.expiracion }}</span>
      </a-list-item>

      <a-list-item class="flex flex-col md:flex-row justify-between">
        <span class="md:font-semibold">Perfiles</span>

        <span class="font-bold md:font-normal text-right">
          <a-tag color="cyan" class="px-2 py-1" v-for="rol in perfilesUsuario" :key="rol">
            {{ rol }}
          </a-tag>
        </span>
      </a-list-item>
    </a-list>
  </a-modal>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import UsuariosApi from "@/api/user";

export default {
  components: {},
  props: {
    usuario: {
      type: Object,
      default: () => ({
        activo: "",
        apellido: "",
        celular: "",
        correo: "",
        estado: "",
        expiracion: "",
        fechaexpirado: null,
        idestablecimiento: "",
        establecimiento: "",
        idestado: "",
        idusuario: "",
        nombre: "",
        usuario: ""
      })
    }
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const perfilesUsuario = ref([]);

    const estadoTag = computed(() => {
      const estados = {
        1: ["Vigente", "green"],
        2: ["Inactivo", "orange"],
        3: ["Bloqueado", "purple"],
        4: ["Deshabilitado", "red"],
        5: ["Contraseña expirada", "pink"]
      };

      const nombre = estados[props.usuario?.idestado][0] || "Estado";
      const color = estados[props.usuario?.idestado][1] || "green";

      return { nombre, color };
    });

    console.log("idusuario", props.usuario.idusuario);

    function fetchPerfilesByUser() {
      UsuariosApi.getPerfilesById(props.usuario?.idusuario)
        .then((res) => {
          perfilesUsuario.value = res.data.map((p) => p.denominacion);
        })
        .catch((err) => console.log(err));
    }

    onMounted(() => {
      fetchPerfilesByUser();
    });

    const closeModal = () => {
      emit("closeModal");
    };

    return {
      closeModal,
      estadoTag,
      perfilesUsuario
    };
  }
};
</script>
