<template>
  <a-page-header @back="() => $router.go(-1)" title="USUARIOS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Configuración</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Usuarios</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="md:flex mb-4">
    <!--------------- Filtros--------------->

    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-center">Filtros</legend>
      <UsuariosFiltros @fetchUsuarios="fetchUsuarios" />
    </fieldset>

    <!--------------- Acciones--------------->

    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-center">Acciones</legend>

      <div class="flex flex-wrap gap-8 md:gap-5">
        <button @click="handleAdd" class="text-center">
          <div class="text-3xl text-green-500">
            <FileAddOutlined />
          </div>
          <p>Nuevo</p>
        </button>

        <button @click="handleEdit" class="text-center" :disabled="isDisabled">
          <div
            class="text-3xl"
            :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400']"
          >
            <FormOutlined />
          </div>
          <p>Editar</p>
        </button>

        <button @click="handleDelete" class="text-center" :disabled="isDisabled">
          <div
            class="text-3xl"
            :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-red-400']"
          >
            <DeleteOutlined />
          </div>
          <p>Eliminar</p>
        </button>

        <button @click="showModalLogs" class="text-center" :disabled="isDisabled">
          <div
            class="text-3xl"
            :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-green-400']"
          >
            <ExceptionOutlined />
          </div>
          <p>Logs</p>
        </button>

        <button @click="showDetails" class="text-center" :disabled="isDisabled">
          <div
            class="text-3xl"
            :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-indigo-400']"
          >
            <FileSearchOutlined />
          </div>
          <p>Detalles</p>
        </button>
      </div>
    </fieldset>
  </div>

  <!--------------- Tabla --------------->
  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="usuariosFormated"
      size="small"
      :pagination="{ defaultCurrent: 1, size: 'large' }"
      :custom-row="customRow"
      rowKey="idusuario"
      :loading="isLoading"
      :rowClassName="
        (record, index) =>
          index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
      "
    >
      <template #rangepages="{ record }">
        <span> {{ usuariosFormated.indexOf(record) + 1 }} </span>
      </template>

      <template #estado="{ text: estado }">
        <span>
          <a-tag :color="estado === 'Vigente' ? 'green' : 'volcano'">
            {{ estado }}
          </a-tag>
        </span>
      </template>
      <template #action="{ record }">
        <div class="flex gap-2 items-center">
          <a-tooltip>
            <template #title>Restablecer contraseña</template>
            <a-button @click="resetPassword(record)">
              <template #icon> <UnlockOutlined /></template>
            </a-button>
          </a-tooltip>

          <a-tooltip>
            <template #title
              >{{ record.idestado == "1" ? "Deshabilitar" : "Habilitar" }} usuario</template
            >
            <a-switch
              :checked="record.idestado == '1'"
              size="small"
              @change="onChangeState(record)"
            />
          </a-tooltip>
        </div>
      </template>
    </a-table>
  </div>
  <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <button class="ml-4" @click="fetchUsuarios">
        <ReloadOutlined />
      </button>
    </div>
    <p>Total de registros: {{ totalDocs }}</p>
  </footer>

  <UsuariosAddEdit
    v-if="isVisible"
    :isVisible="isVisible"
    :usuario="usuario"
    @closeModal="closeModal"
    @fetchUsuarios="fetchUsuarios"
    @clearSelectedArrays="clearSelectedArrays"
  />

  <UsuariosLog
    v-if="isVisibleLogs"
    :isVisible="isVisibleLogs"
    :usuario="usuario"
    @closeModal="closeModal"
    @clearSelectedArrays="clearSelectedArrays"
  />

  <UsuariosDetalle
    v-if="isVisibleDetalle"
    :usuario="usuario"
    :isVisible="isVisibleDetalle"
    @closeModal="closeModal"
  />
</template>

<script>
import { ref, watch, reactive, computed, toRaw, onMounted, createVNode, onUnmounted } from "vue";
import { columns, breadcrumb } from "./utilsUsuarios";
import { useStore } from "vuex";
import { Modal, notification } from "ant-design-vue";
import { nanoid } from "nanoid";
import { filtraUsuarioAdmin } from "@/utils";
import UsuariosApi from "@/api/user";
import UsuariosFiltros from "./UsuariosFiltros.vue";
import UsuariosAddEdit from "./UsuariosAddEdit.vue";
import UsuariosDetalle from "./UsuariosDetalle.vue";
import UsuariosLog from "./UsuariosLog.vue";
import {
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
  UnlockOutlined,
  ExceptionOutlined,
  FileSearchOutlined
} from "@ant-design/icons-vue";
import moment from "moment";

export default {
  name: "AppUsuarios",
  components: {
    UsuariosFiltros,
    UsuariosAddEdit,
    UsuariosDetalle,
    // icons
    FileAddOutlined,
    FormOutlined,
    DeleteOutlined,
    ReloadOutlined,
    UnlockOutlined,
    UsuariosLog,
    ExceptionOutlined,
    FileSearchOutlined
  },
  setup() {
    const store = useStore();
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const isVisibleLogs = ref(false);
    const isVisibleDetalle = ref(false);
    const usuarios = ref([]);
    const isLoading = ref(false);
    const typeAction = ref("");
    const usuario = ref(null);
    const isDisabled = ref(true);
    const state = reactive({
      selectedRowKeys: []
    });
    const { establecimiento } = store.state.user.usuario;

    // ------- trae datos de la api --------
    const fetchUsuarios = () => {
      const filter = store.getters["user/filter"];
      const search = store.getters["user/search"];
      const usuarioActual = store.state.user.usuario.usuario;

      isLoading.value = true;
      UsuariosApi.getAll({ filter, search })
        .then(async (response) => {
          console.log("all usuarios", response);
          usuarios.value = response.data;
          usuarios.value = await filtraUsuarioAdmin(response.data, usuarioActual);
          totalDocs.value = usuarios.value.length;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Configuración", "Usuarios", establecimiento);
      fetchUsuarios();
    });

    onUnmounted(() => {
      store.dispatch("user/setFilter", "all");
      store.dispatch("user/setSearch", "");
    });

    // -------- para selecionar los datos de la tabla --------

    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          usuario.value = toRaw(selectedRows[0]);
        }
      };
    });

    // ------- desactiva la seleccion actual -------
    const customRow = (record) => {
      return {
        onclick: () => {
          usuario.value = record;
        },
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    watch(state, () => {
      isDisabled.value = !state.selectedRowKeys.length;
    });

    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
      isVisibleLogs.value = false;
      isVisibleDetalle.value = false;
      clearSelectedArrays();
    };

    // ------- acciones -------
    const handleAdd = () => {
      usuario.value = {
        usuario: "",
        correo: "",
        apellido: "",
        nombre: "",
        documento: "",
        celular: "",
        idestado: "1",
        fechaexpirado: null,
        isindefinido: true,
        constrasenia: nanoid(12),
        idestablecimiento: "0"
      };

      console.log(usuario.value);
      showModal();
    };

    const handleEdit = () => {
      showModal();
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará al usuario ${usuario.value.nombre} ${usuario.value.apellido}`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          UsuariosApi.deleteMany(toRaw(state.selectedRowKeys))
            .then(() => {
              fetchUsuarios();
              clearSelectedArrays();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const applyFilter = () => {
      alert("Agregar nuevo");
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
    };

    const resetPassword = (record) => {
      console.log(record);
      // Comprobar si no tiene perfiles
      Modal.confirm({
        title: () => "¿Estás seguro de restablecer la contraseña?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          `Se habilitará al usuario y se le enviará un correo de restauración a ${record.correo} para que pueda poner su nueva contraseña`,
        okText: () => "Sí, restablecer",
        okType: "danger",
        cancelText: () => "Cancelar",

        async onOk() {
          await UsuariosApi.changeState(record.idusuario, "1")
            .then(() => {})
            .catch((err) => console.log(err));

          await UsuariosApi.resetIntentos(record.idusuario)
            .then(() => {})
            .catch((err) => console.log(err));

          const payload = record;
          payload.isFromAdmin = 1;

          UsuariosApi.forgot(payload)
            .then(() => {
              notification.success({
                message: "Correo electrónico enviado a " + record.correo
              });
              fetchUsuarios();
            })
            .catch((err) => {
              notification.success({
                message: err.message || "Problemas al enviar el correo"
              });
            });
        }
      });
    };

    const onChangeState = (record) => {
      const activateMsg = {
        text: record.idestado === "1" ? "deshabilitar" : "habilitar",
        accion: record.idestado === "1" ? "deshabilitará" : "habilitará",
        permit: record.idestado === "1" ? "no podrá" : "podrá normalmente"
      };

      Modal.confirm({
        title: () => `¿Estás seguro de ${activateMsg.text} al usuario?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          `Se ${activateMsg.accion} al usuario ${record.nombre} ${record.apellido}, ${activateMsg.permit} acceder al sistema.`,
        okText: () => `Sí, ${activateMsg.text}`,
        okType: "danger",
        cancelText: () => "Cancelar",

        async onOk() {
          const newIdEstado = record.idestado === "1" ? "4" : "1";

          UsuariosApi.getUsuarioRolById(record.idusuario)
            .then(async (res) => {
              if (res.data.length > 0) {
                await UsuariosApi.changeState(record.idusuario, newIdEstado)
                  .then(() => {
                    fetchUsuarios();
                    clearSelectedArrays();
                  })
                  .catch((err) => console.log(err));

                await UsuariosApi.resetIntentos(record.idusuario)
                  .then(() => {})
                  .catch((err) => console.log(err));
              } else {
                notification.error({
                  message: "Operación inválida",
                  description: `Para habilitar al usuario '${record.nombre}', primero debes editarlo y asignarle un perfil`
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const usuariosFormated = computed(() =>
      usuarios.value.map((d) => {
        return {
          ...d,
          expiracion: d.fechaexpirado ? moment(d.fechaexpirado).format("L") : "Indefinido"
        };
      })
    );

    const showDetails = () => {
      isVisibleDetalle.value = true;
    };

    const showModalLogs = () => {
      isVisibleLogs.value = true;
    };

    return {
      breadcrumb,
      isLoading,
      columns,
      usuarios,
      usuariosFormated,
      pageSize,
      customRow,
      rowSelection,
      totalDocs,
      handleDelete,
      handleAdd,
      handleEdit,
      applyFilter,
      closeModal,
      isVisible,
      typeAction,
      fetchUsuarios,
      usuario,
      clearSelectedArrays,
      resetPassword,
      isVisibleLogs,
      isDisabled,
      onChangeState,
      isVisibleDetalle,
      showDetails,
      showModalLogs
      // perfiles
    };
  }
};
</script>

<style></style>
